<template>
  <documents :simulation="true"></documents>
</template>

<script>
import Documents from "../../Works/Documents";

export default {
  name: "SimulationDocuments",
  components: {
    Documents
  }
};
</script>